<template>
    <div class="my_order">
        <div class="conter">
            <div class="orderConfirmation">{{$t('myPay.dd')}}</div>
            <div class="orderConfirmation_underline">
                <div class=orderConfirmation_underline_red></div>
            </div>
            <div class="course_information">
                <h3 class="course_information_h3">{{$t('myPay.kc')}}</h3>
                <div class="course_information_conter">
                    <div class="course_information_left">
                        <img :src="resBasePath+'/'+mess.thumbnailImagePath" alt="" />
                    </div>
                    <div class="course_information_right">
                        <h3 class="course_information_right_h3">
                            {{lang?mess.greatClassRoomCourseName:policyMess.translation.title}}</h3>
                        <div class="course_information_right_streaming" v-if="lang">{{mess.labelName}}｜共
                            {{mess.classHourCount || 0}}课时</div>
                        <div class="course_information_right_streaming" v-if="!lang">
                            {{format.chineseToEn(policyMess.labelName)}}｜total {{policyMess.classHourCount || 0}} class
                            hours</div>
                        <div class="course_information_right_price">￥{{lang?mess.orderAmount:policyMess.price}}</div>
                        <div class="course_information_right_valid">{{$t('myPay.sy')}}</div>
                    </div>
                </div>

            </div>
            <div class="course_end_underline"></div>
            <div class="pay_mode_content">
                <h3>{{$t('myPay.qxz')}}</h3>
                <ul class="pay_mode">
                    <li v-for="(item, index) in payList" :key="index" @click="changePay(item.type)">
                        <img :src="payType==item.type?item.urla:item.url" alt="" />
                    </li>
                </ul>
                <a-statistic-countdown
                    :title="$t('myPay.zy')"
                    :value="mess.paymentExpireTime"
                    @finish="onFinish"
                />
                <!-- <p>{{$t('myPay.zy')}}</p> -->
                <div class="title_book">
                    <div class="book">{{mess.title}}</div>
                    <div class="amount_payable">
                        <span class="amount_pay">{{$t('myPay.yf')}}</span>
                        <span class="amount_money">￥{{lang?mess.orderAmount:policyMess.price}}</span>
                    </div>
                </div>
            </div>
            <div class="course_end_underline"></div>
            <div class="course_end">
                <div class="use_coin">
                    <!-- <GreatIcon type="duihuan" class="icon-img" />&nbsp;&nbsp;使用兑换券 -->
                </div>
                <div class="bannerImg">
                    <a-button v-if="!boughtFlag" class="useNow" :disabled="buyDisabled" @click="buyNow">{{$t('myPay.lj')}}</a-button>
                    <a-button v-if="boughtFlag" class="has-bought">{{$t('myPay.ygm')}}</a-button>
                </div>
                <div class="suc-tip" v-if="boughtFlag">
                    <img class="img" :src="wcImg" alt="">
                    <span class="text">支付成功</span>
                </div>
                <div class="fail-tip" v-if="failBuy">
                    <img class="img" :src="failImg" alt="">
                    <span class="text">支付失败</span>
                </div>
            </div>
        </div>
        <a-modal title="Paypal支付" :visible="visible" destroyOnClose footer="" @ok="handleOk" @cancel="handleCancel">
            <div v-if="visible" id="paypal-button-container"></div>
        </a-modal>
        <div v-if="evisible">
            <a-modal width="420px" title="" :visible="evisible" footer="" @ok="erOk" @cancel="erCancel">
                <div class="zf-m">
                    <div class="zf-title">{{ $t('myPay.ddxx') }}</div>
                    <div class="zf-content" ref="qrcode" v-if="evisible"></div>
                    <div class="zf-img">
                        <img class="img-zfb" v-show="payType==0" :src="aliPayImg" alt="" />
                        <img class="img-wx" v-show="payType==1" :src="weChatPayImg" alt="" />
                    </div>
                    <ul :class="lang?'zf-mess':'zf-mess-en'">
                        <li class="zf-mess-li">
                            <div :class="lang ? 'zf-mess-left' :'zf-mess-left-en'">{{ $t('myPay.ddbh') }}：</div>
                            <a-tooltip placement="topLeft" :title="mess.outTradeNo">
                                <div class="zf-mess-right">{{ mess.outTradeNo}}}</div>
                            </a-tooltip>
                        </li>
                        <li class="zf-mess-li">
                            <div :class="lang ? 'zf-mess-left' :'zf-mess-left-en'">{{ $t('myPay.gmkc') }}：</div>
                            <a-tooltip placement="topLeft" :title="mess.greatClassRoomCourseName">
                                <div class="zf-mess-right">{{ mess.greatClassRoomCourseName}}</div>
                            </a-tooltip>
                        </li>
                        <li class="zf-mess-li">
                            <div :class="lang ? 'zf-mess-left' :'zf-mess-left-en'">{{ $t('myPay.xdrq') }}：</div>
                            <div class="zf-mess-right">{{ format.format(mess.createDate)}}</div>
                        </li>
                        <li class="zf-mess-li">
                            <div :class="lang ? 'zf-mess-left' :'zf-mess-left-en'">{{ $t('myPay.zfje') }}：</div>
                            <div class="zf-mess-right">￥{{ mess.orderAmount}}</div>
                        </li>
                    </ul>
                </div>
            </a-modal>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import GreatIcon from '@/components/Icon.vue'
import QRCode from 'qrcodejs2'
import { loadScript } from '@paypal/paypal-js'
import format from '@/utils/format.js'
export default {
  name: 'myOrder',
  data () {
    return {
      format,
      resBasePath: this.$resBasePath,
      visible: false, // paypal支付
      evisible: false, // 二维码支付
      qrcode: null,
      payType: 0, // 默认选择支付宝
      payList: [
        { url: require('../../assets/image/order_zfb.jpg'), urla: require('../../assets/image/order_zfb_a.png'), name: '支付宝', type: 0 },
        { url: require('../../assets/image/order_wx.jpg'), urla: require('../../assets/image/order_wx_a.png'), name: '微信', type: 1 },
        { url: require('../../assets/image/order_pay.jpg'), urla: require('../../assets/image/order_pay_a.png'), name: 'payPal', type: 2 }
      ],
      aliPayImg: require('../../assets/image/order-ali.png'),
      weChatPayImg: require('../../assets/image/order-wx.png'),
      mess: '', // 商品信息
      policyMess: '', // 英文信息
      payData: '', // 订单信息
      payUrl_alibaba: 'great/official/h5Alipay/h5GreatOfficialCourseAliPayOrderQrCode',
      payUrl_wx: 'great/official/h5WechatPay/h5GreatOfficialCourseWeChatPayOrderQrCode',
      watchAli: 'great/official/h5Alipay/h5GreatOfficialCourseAlipayPollingQueryOrder',
      watchWX: 'great/official/h5WechatPay/h5GreatOfficialCourseWechatPollingQueryOrder',
      payUrl_paypal: '',
      YOUR_CLIENT_ID: '',
      timer: null,
      lang: true,
      orderID: '', // 交易订单ID
      boughtFlag: false, // 是否购买过状态
      failBuy: false, // 默认隐藏购买失败
      wcImg: require('../../assets/image/wancheng.png'),
      failImg: require('../../assets/image/fail.png'),
      buyTimer: null,
      buyDisabled: false
    }
  },
  mounted () {
    this.lang = localStorage.getItem('lang') == 'cn'
    this.payData = this.$store.state.payDataMess
    this.mess = JSON.parse(sessionStorage.getItem('payMess'))
    this.policyMess = JSON.parse(sessionStorage.getItem('policyMess'))
  },
  methods: {
    handleOk () {

    },
    erOk () {

    },
    onFinish () {
      this.buyDisabled = true
    },
    aliWatch () {
      const param = {
        access_token: this.$store.state.access_token,
        outTradeNo: this.payData.outTradeNo
      }
      const url = this.payType == 0 ? this.watchAli : this.watchWX
      const { $notification } = this
      const _this = this
      axios({
        url: _this.$baseUrl + url,
        method: 'POST',
        data: param
      }).then(res => {
        if (res && res.data && res.data.resultData) {
          if (this.lang) {
            $notification.success({
              message: '提示',
              description: '购买成功',
              duration: 5
            })
          } else {
            $notification.success({
              message: 'Tips',
              description: 'buy success',
              duration: 5
            })
          }
          _this.evisible = false
          clearInterval(this.timer)
          _this.timer = null
          _this.boughtFlag = true
          _this.failBuy = false
          _this.buyTimer = setTimeout(() => {
            _this.$router.push({ name: 'classMessDetail' })
          }, 10000)
        }
      })
    },
    handleCancel () {
      this.visible = false
      this.timer = null
    },
    erCancel () {
      this.evisible = false
      clearInterval(this.timer)
    },
    creatQrCode (code) {
      if (this.qrcode) {
        this.qrcode.clear()
      }
      this.$nextTick(function () {
        setTimeout(() => {
          this.qrcode = new QRCode(this.$refs.qrcode, {
            text: code, // 需要转换为二维码的内容 code
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        }, 100)
      })
    },
    getClientId () {
      const { $notification } = this
      const _this = this
      axios({
        url: _this.$baseUrl + 'great/official/h5Paypal/getPaypalClientId',
        method: 'POST',
        data: {
          access_token: _this.$store.state.access_token
        }
      }).then(res => {
        if (res && res.data && res.data.resultCode == 0) {
          loadScript({ 'client-id': res.data.resultData })
            .then((paypal) => {
              paypal.Buttons({
                createOrder: function (data, actions) {
                  const myHeaders = new Headers()
                  myHeaders.append('Content-Type', 'application/json')
                  myHeaders.append('accept', 'application/json')
                  const request = new Request(_this.$baseUrl + 'great/official/h5Paypal/createOfficialCoursePayPalOrder',
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        access_token: _this.$store.state.access_token,
                        outTradeNo: _this.payData.outTradeNo
                      }),
                      headers: myHeaders
                    })
                  return fetch(request).then(function (res) {
                    const resJson = res.json()
                    return resJson
                  }).then(function (orderData) {
                    return orderData.resultData.id
                  })
                },
                // Finalize the transaction after payer approval
                onApprove: function (data, actions) {
                  const myHeaders = new Headers()
                  myHeaders.append('Content-Type', 'application/json')
                  myHeaders.append('accept', 'application/json')
                  const request = new Request(_this.$baseUrl + 'great/official/h5Paypal/captureOfficialCoursePayPalOrder', {
                    method: 'POST',
                    body: JSON.stringify({
                      access_token: _this.$store.state.access_token,
                      paypalOrderNo: data.orderID,
                      outTradeNo: _this.payData.outTradeNo
                    }),
                    headers: myHeaders
                  })
                  return fetch(request).then(function (res) {
                    return res.json()
                  }).then(function (orderData) {
                    _this.boughtFlag = true
                    if (_this.lang) {
                      $notification.success({
                        message: '提示',
                        description: '购买成功',
                        duration: 5
                      })
                    } else {
                      $notification.success({
                        message: 'Tips',
                        description: 'buy success',
                        duration: 5
                      })
                    }
                    _this.visible = false
                    _this.buyTimer = setTimeout(() => {
                      _this.$router.push({ name: 'classMessDetail' })
                    }, 10000)
                  })
                }
              }).render('#paypal-button-container')
            })
        }
      })
    },
    changePay (index) {
      this.payType = index
      this.$forceUpdate()
    },
    // 购买
    buyNow () {
      if (this.payType == 2) {
        this.visible = true
        this.getClientId()
      } else {
        this.tozfbQRcode(this.payData)
      }
    },
    // 生成支付宝或者微信二维码
    tozfbQRcode (item) {
      if (this.payType == 2) {
        this.clearInterval(this.timer)
        this.timer = null
      } else {
        const geturl = this.payType == 0 ? this.payUrl_alibaba : this.payUrl_wx
        const param = {
          access_token: this.$store.state.access_token,
          outTradOrderNo: item.outTradeNo // 本地订单号
          // courseId: item.greatClassRoomCourseId, // 课程id
          // userId: this.$store.state.userInfo.id //用户id
        }
        const _this = this
        axios({
          url: _this.$baseUrl + geturl,
          method: 'POST',
          data: param
        }).then(res => {
          if (res && res.data && res.data.resultCode == 0) {
            _this.$nextTick(() => {
              _this.creatQrCode(res.data.resultData.qrCodeLink)
              _this.evisible = true
              _this.timer = setInterval(() => {
                setTimeout(() => {
                  _this.aliWatch()
                }, 10)
              }, 3000)
            })
          } else {
            _this.$notification.error({
              message: '提示',
              description: '支付遇到问题，请您更换支付方式',
              duration: 5
            })
            _this.failBuy = true
          }
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'myOrder') {
      clearTimeout(this.buyTimer)
      this.buyTimer = null
      next()
    }
  },
  components: {
    GreatIcon
    // PayPal
  }
}
</script>
<style  scoped lang="less">
    .zf-m{
        margin-top: 30px;
        text-align: center;
        .zf-title{
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3A3A3A;
            line-height: 24px;
            text-align: center;
        }
        .zf-content{
            width: 200px;
            height: 200px;
            margin: auto;
            text-align: center;
            margin-top: 15px;
        }
        .zf-img{
            margin-top: 15px;
            img{
                width: 200px;
                height: 100%;
            }
        }
        .zf-mess{
            margin: 10px auto 0;
            width: 240px;
            font-size: 14px;
            .zf-mess-li{
                display: flex;
                width: 100%;
                    .zf-mess-left {
                        width: 70px;
                        text-align: left;
                        color: #3A3A3A;
                    }
                    .zf-mess-right {
                        width: 170px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #666666;
                    }
            }

        }
        .zf-mess-en{
            margin: 10px auto 0;
            width: 320px;
            font-size: 14px;
            .zf-mess-li{
                display: flex;
                .zf-mess-left-en {
                    width: 150px;
                    text-align: left;
                    color: #3A3A3A;
                }

                .zf-mess-right {
                    width: 170px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #666666;
                }
            }

        }
    }
    /deep/.paypal-button-container{
        min-width: 200px;
        max-width: 250px;
    }
    .zf-content{
        width: 180px;
        height:180px;
        margin:auto;
        text-align: center;
    }
    ul,li{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .my_order{
        background: #F2F2F2;
        .conter{
            width: 1200px;
            min-height: 586px;
            background: #FFFFFF;
            border-radius: 10px;
            margin:20px auto;
            padding: 20px 20px 30px;
            .orderConfirmation{
                height: 18px;
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 18px;
                padding: 7px 27px 27px 0px;
                margin-bottom:10px;
            }
            .orderConfirmation_underline{
                width: 1160px;
                height: 1px;
                border: 1px solid #D9D9D9;
                margin:0 auto;
                .orderConfirmation_underline_red{
                    width: 108px;
                    height: 4px;
                    background: @primary-color;
                    border-radius: 2px;
                    margin-top: -2px;

                }
            }
            .course_information{
                h3{
                    height: 18px;
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #666666;
                    line-height: 18px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
                .course_information_conter{
                    display: flex;
                    .course_information_left{
                        width: 233px;
                        height: 131px;
                        img{
                            width:100%;
                            height:131px;

                        }
                    }
                    .course_information_right{
                        margin-left: 20px;
                        margin-top: -19px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        .course_information_right_streaming{
                            height: 20px;
                            font-size: 14px;
                            color: #666666;
                            line-height: 20px;
                            padding-top: 10px;
                            padding-bottom: 14px;
                        }
                        .course_information_right_price{
                            height: 20px;
                            font-size: 20px;
                            font-family: Arial-Black, Arial;
                            font-weight: 900;
                            color: #C60029;
                            line-height: 20px;
                            padding-top: 22px;
                            padding-bottom: 14px;

                        }
                        .course_information_right_valid{
                            padding-top: 24px;
                        }

                    }
                }

            }
            .course_end_underline{
                width: 1160px;
                height: 1px;
                border-top: 2px dotted #D9D9D9;
                margin: 0 auto;
                margin-top: 20px;
            }
            .pay_mode_content{
                h3{
                    height: 14px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 14px;
                    padding-top: 20px;
                    padding-bottom:20px;
                }
                .pay_mode{
                    display: flex;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    cursor: pointer;
                    li{
                        width:150px;
                        height:50px;
                        margin-left: 20px;
                        &:first-child{
                            margin-left: 0px;
                        }
                    }
                }
                p{
                    margin-top: 14px;
                    height: 14px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 14px;

                }
                .title_book{
                    width: 100%;
                    display:flex;
                    .book{
                        flex: 12;
                        margin-top: 14px;
                        height: 14px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 14px;
                    }
                    .amount_payable{
                        flex: 12;
                        text-align: right;
                        .amount_money{
                            height: 24px;
                            font-size: 24px;
                            font-family: Arial-Black, Arial;
                            font-weight: 900;
                            color: @primary-color;
                            line-height: 24px;
                            margin-left: 6px;
                            margin-top: -2px;
                        }
                    }

                }

            }
            .course_end{
                display: flex;
                position: relative;
                .use_coin{
                    flex: 20;
                    margin-top: 40px;
                    display: flex;
                    cursor: pointer;
                }
                .bannerImg{
                    flex: 4;
                    width:100px;
                    margin-top: 20px;
                    text-align: right;
                    .useNow{
                        width: 112px;
                        height: 40px;
                        background: @primary-color;
                        border-radius: 4px;
                        color: white;
                        &[disabled]{
                            background:#DDD;
                            color: #888;
                        }
                    }
                    .has-bought{
                        width: 80px;
                        background: #BDBDBD;
                        border-radius: 4px;
                        cursor: pointer;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                    }
                }
                .suc-tip{
                    width: 380px;
                    height: 80px;
                    background: #39AF37;
                    box-shadow: 0px 2px 12px 0px rgba(31, 82, 30, 0.4000);
                    border-radius: 47px;
                    display: flex;
                    position: absolute;
                    left: 50%;
                    top: 10px;
                    margin-left: -190px;
                    text-align: center;
                    line-height: 80px;
                    .img{
                        width: 36px;
                        height: 36px;
                        margin-left: 32%;
                        margin-top: 20px;
                    }
                    .text{
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 40px;
                        margin-top: 18px;
                        margin-left: 10px;
                    }
                }
                .fail-tip{
                    width: 380px;
                        height: 80px;
                        background: #D02900;
                        box-shadow: 0px 2px 12px 0px rgba(31, 82, 30, 0.4000);
                        border-radius: 47px;
                        display: flex;
                        position: absolute;
                        left: 50%;
                        top: 10px;
                        margin-left: -190px;
                        text-align: center;
                        line-height: 80px;

                        .img {
                            width: 36px;
                            height: 36px;
                            margin-left: 32%;
                            margin-top: 20px;
                        }

                        .text {
                            font-size: 24px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 40px;
                            margin-top: 18px;
                            margin-left: 10px;
                        }
                }
            }

        }
    }
</style>
