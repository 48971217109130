import moment from 'moment'

/**
 * format unix timestamp to YYYY-MM-DD HH:mm:ss
 * @param {number} timestamp unix timestamp (10 bits)
 */
export default {
  format (timestamp) {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
  },
  formatDay (timestamp) {
    return moment(timestamp).format('MM.DD')
  },
  formatDayTime (timestamp) {
    return moment(timestamp).format('YYYY-MM-DD')
  },
  formateSeconds (endTime) {
    let secondTime = parseInt(endTime / 1000)// 将传入的秒的值转化为Number
    let min = 0// 初始化分
    let h = 0// 初始化小时
    let result = ''
    if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
      min = parseInt(secondTime / 60) // 获取分钟，除以60取整数，得到整数分钟
      secondTime = parseInt(secondTime % 60) // 获取秒数，秒数取佘，得到整数秒数
      if (min > 60) { // 如果分钟大于60，将分钟转换成小时
        h = parseInt(min / 60) // 获取小时，获取分钟除以60，得到整数小时
        min = parseInt(min % 60) // 获取小时后取佘的分，获取分钟除以60取佘的分
      }
    }
    result = `${h.toString().padStart(2, '0')} : ${min.toString().padStart(2, '0')} : ${secondTime.toString().padStart(2, '0')}`
    return result
  },
  parseJson (val) {
    const result = JSON.parse(val)
    return result
  },
  liveStatus (val) { // 名师之路
    let result = ''
    switch (val) {
      case 1:
        result = '正在直播'
        break
      case 2:
        result = '暂未开始'
        break
      case 3:
        result = '已结束'
        break
    }
    return result
  },
  classType (val) { // 长城课堂
    let result = ''
    switch (val) {
      case 1:
        result = '直播课'
        break
      case 2:
        result = '视频课'
        break
      case 3:
        result = '多媒体课'
        break
    }
    return result
  },
  classTypeEn (val) { // 长城课堂英文版
    let result = ''
    switch (val) {
      case 1:
        result = 'Live class'
        break
      case 2:
        result = 'Video class'
        break
      case 3:
        result = "Students' class"
        break
    }
    return result
  },
  buy (val) { // 长城课堂报名还是购买
    let result = ''
    switch (val) {
      case 1:
        result = '立即报名'
        break
      default:
        result = '立即购买'
    }
    return result
  },
  buyEN (val) { // 长城课堂报名还是购买
    let result = ''
    switch (val) {
      case 1:
        result = 'Purchased'
        break
      default:
        result = 'Buy now'
    }
    return result
  },
  // 长城课堂根据中文展示为英文
  chineseToEn (val) {
    let result = ''
    switch (val) {
      case '直播课':
        result = 'Live'
        break
      case '多媒体课':
        result = 'Student'
        break
      case '视频课':
        result = 'Video'
        break
      case '文化园':
        result = 'Cultural Park'
        break
      case '京剧':
        result = 'Peking Opera Park'
        break
    }
    return result
  },
  forMale (val) {
    let result = ''
    switch (val) {
      case '老师':
        result = 'Teacher'
        break
      default:
        result = 'General User'
    }
    return result
  },
  payStatus(val){
    let result = ''
    switch (val) {
      case 0:
        result = '待支付'
        break
      case 1:
        result = '已支付'
        break
      case 2:
        result = '支付失败'
        break
      case 3:
        result = '支付取消'
        break
    }
    return result
  }
}
